<template>
    <v-container class="grey lighten-5">
        <v-row no-gutters>
            <v-col cols="4" class="text-center">
                <img src="https://firma.demo.gtic.gob.bo/img/logo_ciudadania2.png" width="150"/>
            </v-col>
            <v-col cols="4"></v-col>
            <v-col cols="4" class="text-center">
                <img src="https://firma.demo.gtic.gob.bo/img/logo_bolivia.png" width="70" />
            </v-col>
            <v-col cols="12" class="text-center">
                <p>
                    Confirmación de aprobación de documentos y envío a la cadena de bloques
                </p>
                <p>
                    Documento: Documento RPA <br/>
                    Sistema: PLATAFORMA DGRPA - TEST
                </p>
                <p class="alert q-mr-lg">
                    Descargue este documento, así podrá verificarlo cuando lo requiera.
                </p>
                <div class="col-12 column items-center q-pt-md">
                    <p>¿Deseas aprobar este documento con Ciudadanía digital?</p>
                </div>
            </v-col>
            <v-col cols="col-6"  class="text-right pr-5">
                <v-btn color="light-green accent-3" rounded @click="confirmar(false)">Rechazar</v-btn>
            </v-col>
            <v-col cols="col-6"  class="text-left pl-5">
                <v-btn color="indigo darken-4" rounded  dark @click="confirmar">Confirmar</v-btn>
            </v-col>
        </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            urlBase:process.env.VUE_APP_API_RPA_URL
        }
    },
    methods:{
        uuidv4(){
             return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            )
        },
       confirmar(acpetado = true){

           const tramite = this.$route.query.tramite
            const headers = {
                Authorization: process.env.VUE_APP_TOKEN_APROBACION
            }

            const body = {
                aceptado: true,
                introducido: true,
                requestUuid: tramite,
                codigoOperacion: this.uuidv4(),
                mensaje: 'COMPLETADO DE PRUEBAS',
                transaction_id: this.uuidv4(),
                fechaHoraSolicitud: Date.now(),
                hashDatos: '53b9f4e881e3b097b4e58e79fed7a93bcd5c21ca991c7387f492adccb548d962',
                ci: '7039950'
            }

            if(!acpetado){
                body.aceptado = false
                body.introducido = false
                body.mensaje = 'SE RECHAZO LA APROBACION DEL TRÁMITE'
                body.codigoOperacion = ''
                body.transaction_id = '',
                body.hashDatos = '53b9f4e881e3b097b4e58e79fed7a93bcd5c21ca991c7387f492adccb548d963'
            }

            axios.post(`${this.urlBase}io/v1/aprobacion-documento/notificacion`, body, { headers })
                .then(({ data }) => {
                    if (data.finalizado) {
                        window.location.href = `/abogado/solicitud-aprobacion-documentos?requestUuid=${tramite}&estado=true&finalizado=true&mensaje=firmado&linkVerificacion=http://gob.bo/&linkVerificacionVerificacionUnico=http://gob.bo&transactionCode=${this.uuidv4()}`
                    }
                }).catch(error => {
                    console.log('------------------------------------')
                    console.log(error)
                    console.log('------------------------------------')
                })
            } 
    }
}
</script>